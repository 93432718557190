import React, { Component } from "react";
import axios from "axios";

//componentes
import Alert from "../../Mensajes/alert";
import "./login.css";

class LoginAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redireccionar: false,
      usuario: "",
      clave: "",
      message: "",
      formulario: "",
      mensajealerta: false,
      tokencorreo: "",
      tokenBackend: "",
      tokencorON: false,
      tokenpasoON: false,
      clavenueva: "",
      representante: "",
    };
  }

  limpiar = () => {
    this.setState({
      redireccionar: false,
      clave: "",
      message: "",
      formulario: "",
      mensajealerta: false,
      tokencorreo: "",
      tokenBackend: "",
      tokencorON: false,
      tokenpasoON: false,
      clavenueva: "",
      representante: "",
    });
  };

  registro(e) {
    this.setState({
      usuario: "",
      clave: "",
      nombre: "",
      correo: "",
      nivel: "",
    });
  }

  verificar() {
    if (this.state.clave.length > 0 && this.state.usuario.length > 0) {
      let data = new FormData();
      data.append("pas", this.state.clave);
      data.append("usu", this.state.usuario);
      data.append("tok", "kamiscake.com.ve");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/valida.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          var clav = String(res.data.claveUsu);
          var username = res.data.nombreUsu;
          var nivel = String(res.data.nivelUsu);
          var tokenp = res.data.token;

          if (
            (clav.length === 0) &
            (username.length === 0) &
            (tokenp === "NO")
          ) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inicio de Sesion",
              cuerpoM:
                "El Usuario No Exite, Debe registrarse Para Iniciar una Sesion...",
            });
          }
          if (clav === this.state.clave && nivel === "1") {
            console.log("paso bien");
            this.props.onClose();
            // this.setState({
            //   redireccionar: true,
            // });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inicio de Sesion",
              cuerpoM:
                "No se a podido Validar los datos Favor Verificar el Usuario y la Clave o Cominicarse con la Administración Gracias...",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Inicio de Sesion",
        cuerpoM:
          "No se puede dejar campos en blanco debe verificar el Usuario o la Clave Gracias...",
        message: "Debe incluir el Usuario y Cliente",
      });
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  render() {
    if (this.state.formulario === "") {
      return (
        <div>
          <form onSubmit={this.handleSubmit}>
            {this.state.mensajealerta === true && (
              <Alert
                tituloMensaje={this.state.tituloM}
                cuerpoMensaje={this.state.cuerpoM}
                open={this.state.mensajealerta}
                handleClose={this.handleClose}
              />
            )}
            <div className="formulario">
              <h2>Inicio de Sesion</h2>
              <input
                value={this.state.usuario}
                onChange={this.onChange.bind(this)}
                type="text"
                name="usuario"
                id="usuario"
                autoComplete="off"
                placeholder="&#128101; Usuario"
              />
              {!this.state.tokencorON && (
                <input
                  value={this.state.clave}
                  onChange={this.onChange.bind(this)}
                  type="password"
                  name="clave"
                  id="clave"
                  maxLength="15"
                  placeholder="&#128272; Clave"
                />
              )}

              <div className="acciones">
                {!this.state.tokencorON && (
                  <button
                    className="aceptar"
                    onClick={this.verificar.bind(this)}
                  >
                    Aceptar
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default LoginAction;
