import React, { useState, useRef } from "react";
import {
  Container,
  Card,
  CardContent,
  makeStyles,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import QRCode from "qrcode";
import QrReader from "react-qr-reader";
import axios from "axios";
import RingLoader from "react-spinners/ScaleLoader";
import moment from "moment";

//imagenes
import Alert from "./modal/alert";
import logo1 from "./imagenes/Logo.png";
import "./App.css";
import Reloj from "./reloj";
import Login from "./componets/login/Login";

function App() {
  const [text, setText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [scanResultFile, setScanResultFile] = useState("");
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");
  const [personal, setpersonal] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [loginOn, setLoginOn] = React.useState(false);
  const [fechaT, setfechaT] = React.useState(moment().format("YYYY-MM-DD"));
  const [horaT, sethoraT] = React.useState(moment().format("HH:MM:SS"));
  const classes = useStyles();
  const qrRef = useRef(null);

  const generateQrCode = async () => {
    try {
      const response = await QRCode.toDataURL(text);
      setImageUrl(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleErrorFile = (error) => {
    console.log(error);
  };
  const handleScanFile = (result) => {
    if (result) {
      setScanResultFile(result);
    }
  };
  const onScanFile = () => {
    qrRef.current.openImageDialog();
  };
  const handleErrorWebCam = (error) => {
    console.log(error);
  };

  const handleClose = () => {
    setmensajealerta(false);
  };

  const buscarAsistencias = () => {
    let form = new FormData();
    form.append("boton", "buscarAsistencias");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/asistencia.php";
    axios
      .post(xurl, form, config)

      .then((res) => {
        let xresult = res.data.variable2;
        setpersonal(xresult);
        //setScanResultWebCam(result);
      });
  };

  const handleScanWebCam = (result) => {
    if (result) {
      setloading(true);
      let form = new FormData();
      form.append("cedulaPersonal", result);
      form.append("fecha", fechaT);
      //form.append("hora", horaT);
      form.append("hora", new Date().toLocaleTimeString());
      form.append("boton", "guardarHoraEntrada");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/asistencia.php";
      axios
        .post(xurl, form, config)

        .then((res) => {
          if (res.data.variable1) {
            buscarAsistencias();
            setloading(false);
          } else {
            setloading(false);
            setmensajealerta(true);
            settituloM("Menu Asistencia de Empleado.");
            setcuerpoM(res.data.variable2);
          }
          //setScanResultWebCam(result);
        });
    }
  };

  React.useEffect(() => {
    buscarAsistencias();
  }, []);
  return (
    <Container className={classes.conatiner}>
      {!loginOn && (
        <Login
          // open={this.state.mostrarOn}
          // someHTML2={this.state.someHTML2}
          // imprimir={this.imprimirP}
          onClose={() => setLoginOn(true)}
        />
      )}

      {loginOn && (
        <Card>
          <h2 className={classes.title}>
            Sistema de Asistencia Instituto Bilingue las Naciones
          </h2>
          <CardContent>
            <Grid container spacing={2}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <img src={logo1} alt="img" />
                  </Grid>
                </Grid>
              </CardContent>
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <div className="contenedor-cedula col-2">
                  <Reloj />
                  {/* <label className="labelcedulac">Fecha.</label>
                <input
                  className="form-control"
                  type="date"
                  name="fechaT"
                  id="fechaT"
                  autoComplete="off"
                  onChange={(e) => setfechaT(e.target.value)}
                  value={fechaT}
                />
                {" "}{" "}
            
                <label className="labelcedulac">Hora.</label>
                <input
                  className="form-control"
                  type="time"
                  name="horaT"
                  id="horaT"
                  autoComplete="off"
                  onChange={(e) => sethoraT(e.target.value)}
                  value={horaT}
                /> */}
                </div>
                <div className="cont-listnomi row">
                  <div className="listnomi col-12">
                    <table className="tablanomi" border="1">
                      <thead className="theadcreartabla">
                        <tr className="trcreartabla">
                          {/* <th className="xtitunomina1">item.</th> */}
                          <th className={classes.xtitunomina2x}>Trabajador.</th>
                          <th className="xtitunomina2x">Hora.</th>
                          <th className="xtitunomina1x">Horario.</th>
                          <th className="xtitunomina1x">Tipo.</th>
                        </tr>
                      </thead>

                      <tbody className="evocuerpo1">
                        {personal.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className={classes.nombrePersonalx}
                                width="20"
                              >
                                {item.variable2}
                              </td>
                              <td
                                className={classes.nombrePersonalx}
                                width="20"
                              >
                                {item.variable3}
                              </td>

                              <td
                                className={classes.nombrePersonalx}
                                width="20"
                              >
                                {item.variable4}
                              </td>
                              <td
                                className={classes.nombrePersonalx}
                                width="20"
                              >
                                {item.variable5}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* </QrReader> */}
                {/* <h3>Scanear Codigo: {scanResultFile}</h3> */}
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                {/* <h3>Leer Codigo Qr por Web Cam</h3> */}
                {mensajealerta === true && (
                  <Alert
                    tituloMensaje={tituloM}
                    cuerpoMensaje={cuerpoM}
                    open={mensajealerta}
                    handleClose={handleClose}
                  />
                )}
                {loading === true && (
                  <div className="cont-spinner row">
                    <div className="spinner col-6">
                      <RingLoader
                        clasName="spinner"
                        sizeUnit={"px"}
                        size={160}
                        width={19}
                        radius={20}
                        height={160}
                        color={"#48e120"}
                        loading={loading}
                      />
                    </div>
                  </div>
                )}
                {!loading === true && (
                  <QrReader
                    delay={300}
                    style={{ width: "100%" }}
                    onError={handleErrorWebCam}
                    onScan={handleScanWebCam}
                  />
                )}
                <h3>
                  Coloque frente a la Camara el codigo QR: {scanResultWebCam}
                </h3>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  conatiner: {
    marginTop: 10,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#3f51b5",
    color: "#fff",
    padding: 20,
  },
  btn: {
    marginTop: 10,
    marginBottom: 20,
  },
  xtitunomina2x: {
    width: 200,
    fontSize: 18,
  },
  nombrePersonalx: {
    fontSize: 12,
  },
}));
export default App;
