import React, { Component } from "react";
//componentes
import "./index.css";

function Reloj(props) {
//   const [listaOn, setlistaOn] = React.useState(false);
//   const [tituloM, settituloM] = React.useState("");
  const [el, setEl] = React.useState("");

  const buscarPersonal = () => {
    
  };

  React.useEffect(() => {
    setInterval(() => {
        let now = new Date().toLocaleTimeString()
        setEl(<span className="hora">{now}</span>);
      }, 1000);
  }, []);

  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
          <span className="hora">Hora: {" "}{el}</span>
      </div>
    </div>
  );
}
export default (Reloj);
